define("flytrendy/helpers/NumberFormat", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (options) {
    var num = options[0];

    if (num) {
      num = (0, _numeral.default)(num).format('0.0a');
    } else {
      num = 0;
    }

    return num;
  });

  _exports.default = _default;
});