define("flytrendy/templates/components/post-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C0ANuZB8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"user-cont\"],[9],[0,\"\\n\\t\"],[7,\"img\"],[12,\"src\",[23,[\"value\",\"profile_picture\"]]],[9],[10],[0,\"\\n\\t\"],[7,\"p\"],[9],[1,[23,[\"value\",\"name\"]],false],[10],[0,\"\\n\\t\"],[7,\"p\"],[9],[1,[23,[\"value\",\"followers\"]],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "flytrendy/templates/components/post-user.hbs"
    }
  });

  _exports.default = _default;
});