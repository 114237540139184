define("flytrendy/models/objects/brand", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    id: "",
    user: "",
    company: "",
    phone: "",
    mobile: "",
    street_address: "",
    location_city: "",
    location_state: "",
    location_postcode: "",
    location_country: "",
    origin: "",
    brand_currency: "",
    total_campaigns: "",
    created_datetime: "",
    brand_logo: "",
    showLoader: false,
    init: function init(obj) {},
    setLogo: function setLogo(controller) {
      var that = this;
      var userSession = controller.get("session");

      if (!userSession.data) {
        return;
      }

      var userauth = "Bearer " + userSession.data.authenticated.access_token;
      this.set("showLoader", true);
      Ember.$.ajax({
        type: "GET",
        url: _environment.default.APP.API_HOST + "/staff/brandlogo?brand_id=" + this.get("id"),
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader("Authorization", userauth);
          xhr.setRequestHeader("Content-Type", "application/json");
        },
        success: function success(res) {
          if (res && res.getBrandLogoStaff) {
            that.set("brand_logo", res.getBrandLogoStaff);
          }

          setTimeout(function () {
            that.set("showLoader", false);
          }, 500);
        },
        error: function error(jqXHR, exception) {
          that.set("showLoader", false);
        }
      });
    }
  });

  _exports.default = _default;
});