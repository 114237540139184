define("flytrendy/adapters/application", ["exports", "ember-data", "flytrendy/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:application',
    host: _environment.default.APP.API_HOST
  });

  _exports.default = _default;
});