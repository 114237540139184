define("flytrendy/router", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login', {
      path: '/'
    });
    this.route('brands');
    this.route('influencers');
    this.route('campaigns', {
      path: '/campaigns'
    });
    this.route('posts', {
      path: '/posts'
    });
    this.route('campaigns-pending', {
      path: '/campaigns-pending'
    });
    this.route('posts-pending', {
      path: '/posts-pending'
    });
    this.route('post-payments', {
      path: '/post-payments'
    });
    this.route('view-campaign', {
      path: '/view-campaign'
    });
    this.route('analytics');
    this.route('push');
  });
  var _default = Router;
  _exports.default = _default;
});