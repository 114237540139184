define("flytrendy/templates/components/post-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y3QjW9WB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"price\"],[9],[0,\"€\"],[1,[21,\"value\"],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "flytrendy/templates/components/post-price.hbs"
    }
  });

  _exports.default = _default;
});