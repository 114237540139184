define("flytrendy/templates/brands", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FVL8sPcH",
    "block": "{\"symbols\":[\"brand\"],\"statements\":[[7,\"div\"],[11,\"class\",\"main-wrapper\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\\t\\t\"],[7,\"h2\"],[11,\"class\",\"title\"],[9],[0,\"Brands\"],[10],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"data-content\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"data\"]]],null,{\"statements\":[[4,\"link-to\",[\"campaigns\",[27,\"query-params\",null,[[\"brand\"],[[22,1,[\"id\"]]]]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"brand-card\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"showLoader\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"logo\"],[11,\"style\",\"background-image: url('images/spinner.gif'); background-size: 35px;\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"logo\"],[12,\"style\",[28,[\"background-image: url('\",[22,1,[\"brand_logo\"]],\"'); background-size: contain;\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"card-data\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[7,\"p\"],[11,\"class\",\"card-title\"],[9],[1,[22,1,[\"company\"]],false],[10],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[7,\"p\"],[9],[0,\"Campaigns \"],[7,\"span\"],[9],[1,[22,1,[\"total_campaigns\"]],false],[10],[10],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[7,\"p\"],[9],[0,\"Created \"],[7,\"span\"],[9],[1,[27,\"moment-format\",[[22,1,[\"created_datetime\"]],\"DD/MM/YY hh:mm a\",\"YYYY-MM-DD H:mm:ss\"],null],false],[10],[10],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\\t\\t\\t\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"p\"],[9],[0,\"loading...\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "flytrendy/templates/brands.hbs"
    }
  });

  _exports.default = _default;
});