define("flytrendy/components/bar-chart", ["exports", "ember-google-charts/components/bar-chart"], function (_exports, _barChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _barChart.default;
    }
  });
});