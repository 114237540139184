define("flytrendy/templates/components/post-platform", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W3AK15Yq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[21,\"value\"]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "flytrendy/templates/components/post-platform.hbs"
    }
  });

  _exports.default = _default;
});