define("flytrendy/utils/g-maps/child-collection", ["exports", "ember-cli-g-maps/utils/g-maps/child-collection"], function (_exports, _childCollection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _childCollection.default;
    }
  });
});