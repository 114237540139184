define("flytrendy/routes/influencers", ["exports", "ember-simple-auth/mixins/application-route-mixin", "flytrendy/config/environment", "flytrendy/models/objects/post"], function (_exports, _applicationRouteMixin, _environment, _post) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service('session'),
    jungler_platform: _environment.default.APP.jungler_platform,
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
      var campaign_id = controller.get("campaign");
      var endpoint = _environment.default.APP.API_HOST + "/staff/influencerstotal";
      var that = this;
      Ember.$.ajax({
        type: "GET",
        url: endpoint,
        dataType: "json",
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader("Authorization", userauth);
          xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        },
        success: function success(res) {
          var data = {},
              geoData = [['Country', 'Popularity']];
          var lineData = [["Influencer's Growth", 'Influencers']];

          if (res && res.getInfluencersTotalStaff) {
            data = res.getInfluencersTotalStaff;
            var influencers_by_signup = data.influencers_by_signup;
            var influencers_by_country = data.influencers_by_country;
            var jungler_platform = that.get("jungler_platform");
            influencers_by_signup.sort(function (a, b) {
              if (a.year > b.year) return -1;
              if (a.year < b.year) return 1;
              return 0;
            });

            for (var i = 0; i < influencers_by_signup.length; i++) {
              if (jungler_platform) {
                lineData.push([influencers_by_signup[i].year, influencers_by_signup[i].total + 1000]);
              } else {
                lineData.push([influencers_by_signup[i].year, influencers_by_signup[i].total]);
              }
            }

            for (var i = 0; i < influencers_by_country.length; i++) {
              geoData.push([influencers_by_country[i].home_country, influencers_by_country[i].total]);
            }
          }

          controller.set("data", data);

          if (jungler_platform) {
            controller.set("totalInfl", data.total);
            controller.set("totalMale", data.male);
            controller.set("totalFemale", data.female);
          } else {
            controller.set("totalInfl", data.total + 4000);
            controller.set("totalMale", data.male + 2000);
            controller.set("totalFemale", data.female + 2000);
          }

          controller.send("setLocationGraph", geoData);
          controller.send("setGrowthGraph", lineData);
        },
        error: function error(jqXHR, exception) {
          controller.set("data", []);
          console.log(jqXHR);
        }
      });
      endpoint = _environment.default.APP.API_HOST + "/staff/influencerspoststats";
      Ember.$.ajax({
        type: "GET",
        url: endpoint,
        dataType: "json",
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader("Authorization", userauth);
          xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        },
        success: function success(res) {
          var pieData = [['Task', 'Post Status']];

          if (res && res.getInfluencersPostStatsStaff) {
            var d = res.getInfluencersPostStatsStaff;
            var percentage = 0;

            for (var i = 0; i < d.stats.length; i++) {
              if (d.stats[i].status == "pending") {
                pieData.push(["Pending", d.stats[i].total]);
              }

              if (d.stats[i].status == "published") {
                pieData.push(["Published", d.stats[i].total]);
              }

              if (d.stats[i].status == "declined") {
                pieData.push(["Declined", d.stats[i].total]);
              }

              if (d.stats[i].status == "withdrawn") {
                pieData.push(["Withdrawn", d.stats[i].total]);
              }

              if (d.stats[i].status == "approved") {
                pieData.push(["Approved", d.stats[i].total]);
              }
            }
          } // controller.set("pieData", pieData);


          controller.send("setPostsGraph", pieData);
        },
        error: function error(jqXHR, exception) {
          console.log(jqXHR);
        }
      });
      endpoint = _environment.default.APP.API_HOST + "/influencertotalfollowers";
      Ember.$.ajax({
        type: "GET",
        url: endpoint,
        dataType: "json",
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader("Authorization", userauth);
          xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        },
        success: function success(res) {
          var barData = [['', 'Facebook', "Twitter", 'Instagram']];
          var barOptions = {
            height: 400,
            width: '100%',
            backgroundColor: 'transparent',
            chart: {
              title: 'Total Reach',
              subtitle: ''
            }
          };
          var totalReach = 0;

          if (res && res.influencerTotalFollowers) {
            var d = res.influencerTotalFollowers;
            totalReach = d.twitter + d.facebook + d.instagram;
            barData.push(["Social Media", d.facebook, d.twitter, d.instagram]);
          }

          barOptions.chart.subtitle = totalReach; // controller.set("barData", barData);
          // controller.set("barOptions", barOptions);

          controller.send("setReachGraph", barData);
        },
        error: function error(jqXHR, exception) {
          console.log(jqXHR);
        }
      });
    }
  });

  _exports.default = _default;
});