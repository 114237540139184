define("flytrendy/templates/components/post-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DKZ+SMSr",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"status \",[21,\"value\"]]]],[9],[1,[21,\"value\"],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "flytrendy/templates/components/post-status.hbs"
    }
  });

  _exports.default = _default;
});