define("flytrendy/controllers/posts", ["exports", "flytrendy/config/environment", "ember-light-table", "ember-concurrency", "flytrendy/models/objects/post", "flytrendy/models/objects/campaign"], function (_exports, _environment, _emberLightTable, _emberConcurrency, _post, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    campaign: null,
    platforms: _environment.default.APP.platforms,
    self_service: _environment.default.APP.self_service,
    queryParams: ['campaign'],
    data: [],
    databyids: {},
    campaigns: [],
    jungler_platform: _environment.default.APP.jungler_platform,
    store: Ember.inject.service(),
    page: 0,
    canLoadMore: true,
    enableSync: true,
    isLoadingData: true,
    galleryOptions: {
      hideShare: true,
      hideZoomInOut: false,
      hideToggleFullScreen: false
    },
    table: null,
    postGalleryData: [{}],
    init: function init() {
      this._super.apply(this, arguments);

      var table = _emberLightTable.default.create({
        columns: this.get('columns'),
        rows: this.get('data')
      });

      this.set('table', table);
    },
    actions: {
      onLoading: function onLoading() {
        var that = this;
        this.send("getCampaigns", function () {
          var campaigns = that.get("campaigns");

          if (campaigns.length > 0) {
            var campaign_id = campaigns[0].id; // that.set("campaign", campaign_id);

            that.send("loadPageData");
          }
        });
      },
      onFilterPosts: function onFilterPosts(e) {
        var val = $(e.target).find(":checked").val().toLowerCase();
        this.set("page", 0);
        this.send("setupData");
        this.set("canLoadMore", true);
        this.set("data", []);

        if (val === "flytrendy_group") {
          this.send("loadPageData3rdParty", "flytrendy_group");
        } else if (val === "abu_media") {
          this.send("loadPageData3rdParty", "abu_media");
        } else if (val === "io_platform") {
          this.send("loadPageData3rdParty", "io_platform");
        } else if (val === "ec_platform") {
          this.send("loadPageData3rdParty", "ec_platform");
        } else if (val === "caatch") {
          this.send("loadPageData3rdParty", "caatch");
        } else {
          this.send("loadPageData");
        }
      },
      onChnageCampagin: function onChnageCampagin() {
        var selectedCampaignID = $("#post_campaigns_list option:selected").val();
        this.set("campaign", selectedCampaignID);
        this.set("databyids", {});
        this.send("setupData");
        this.set("page", 0);
        this.set("canLoadMore", true);
        this.set("data", []);
        this.send("loadPageData");
      },
      getCampaigns: function getCampaigns(cb) {
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var that = this;
        Ember.$.ajax({
          type: "GET",
          url: _environment.default.APP.API_HOST + "/staff/campaigns",
          dataType: "json",
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", userauth);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
          },
          success: function success(res) {
            var data = [];

            if (res && res.getCampaignsStaff) {
              var obj = null,
                  campaignList = [],
                  campObj = null;

              for (var i = 0; i < res.getCampaignsStaff.length; i++) {
                obj = res.getCampaignsStaff[i];
                campObj = _campaign.default.create(obj);
                campObj.set('data_id', obj.id);
                campObj.setHeroImage(that);
                data.push(campObj);
              }
            }

            that.set("campaigns", data);
            cb();
          },
          error: function error(jqXHR, exception) {
            that.set("campaigns", []);
            cb();
          }
        });
      },
      viewPost: function viewPost(row) {
        // row.get('post_social_type')
        // $("#post_view_dialog").show();
        // Option buttons to be added -- #BUG
        // https://photoswipe.com/documentation/custom-html-in-slides.html
        var that = this,
            postObj = null;
        var is_video = row.get("video_url");

        if (is_video) {
          var video = document.createElement("video");
          video.src = row.get('video_url');
          $.colorbox({
            html: "<video width='720' controls autoplay><source src=" + video.src + " type='video/mp4'></video>"
          });
        } else {
          var img = document.createElement("img");
          img.src = row.get('post_image');

          img.onload = function () {
            postObj = new Object();
            postObj.src = row.get("post_image");
            postObj.w = img.width;
            postObj.h = img.height;
            postObj.title = row.get("post_copy");
            that.set("postGalleryData", [postObj]);
            var pswpElement = document.querySelectorAll('.pswp')[0];
            var gallery = new PhotoSwipe(pswpElement, false, that.get("postGalleryData"), {
              bgOpacity: 0.8,
              history: false,
              focus: false,
              showAnimationDuration: 0,
              hideAnimationDuration: 0
            });
            gallery.init();
          };
        }
      },
      onPostViewClose: function onPostViewClose() {
        $("#post_view_dialog").hide();
      },
      loadPageData: function loadPageData() {
        this.set("isLoadingData", true);
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var campaign_id = this.get("campaign");
        var that = this,
            page = this.get("page");
        var endpoint = _environment.default.APP.API_HOST + "/staff/posts?page=" + page;

        if (campaign_id) {
          endpoint = _environment.default.APP.API_HOST + "/staff/posts?campaign_id=" + campaign_id + "&page=" + page;
        }

        Ember.$.ajax({
          type: "GET",
          url: endpoint,
          dataType: "json",
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", userauth);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
          },
          success: function success(res) {
            var data = that.get("data"),
                canLoadMore = false,
                databyids = that.get("databyids");

            if (res && res.getPostsStaff) {
              var obj = null,
                  postObj = null;

              for (var i = 0; i < res.getPostsStaff.length; i++) {
                obj = res.getPostsStaff[i];

                if (!databyids[obj.id]) {
                  postObj = _post.default.create(obj);
                  postObj.setPostImage(that);
                  databyids[obj.id] = postObj;
                  data.push(postObj);
                }
              }

              if (res.getPostsStaff.length > 0) {
                page = page + 1;
                canLoadMore = true;
              }
            }

            that.set("databyids", databyids);
            that.set("data", data);
            that.set("canLoadMore", canLoadMore);
            that.set("page", page);
            that.send("setupData");
            that.set("isLoadingData", false);
          },
          error: function error(jqXHR, exception) {
            if (page == 0) {
              that.set("data", []);
            }

            that.set("isLoadingData", false);
          }
        });
      },
      loadPageData3rdParty: function loadPageData3rdParty(third_party) {
        this.set("isLoadingData", true);
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var that = this,
            page = this.get("page");
        var endpoint = _environment.default.APP.API_HOST + "/staff/posts?" + third_party + "=true&page=" + page;
        Ember.$.ajax({
          type: "GET",
          url: endpoint,
          dataType: "json",
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", userauth);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
          },
          success: function success(res) {
            var data = that.get("data"),
                canLoadMore = false,
                databyids = that.get("databyids");

            if (res && res.getPostsStaff) {
              var obj = null,
                  postObj = null;

              for (var i = 0; i < res.getPostsStaff.length; i++) {
                obj = res.getPostsStaff[i];

                if (!databyids[obj.id]) {
                  postObj = _post.default.create(obj);
                  postObj.setPostImage(that);
                  databyids[obj.id] = postObj;
                  data.push(postObj);
                }
              }

              if (res.getPostsStaff.length > 0) {
                page = page + 1;
                canLoadMore = true;
              }
            }

            that.set("databyids", databyids);
            that.set("data", data);
            that.set("canLoadMore", canLoadMore);
            that.set("page", page);
            that.send("setupData");
            that.set("isLoadingData", false);
          },
          error: function error(jqXHR, exception) {
            if (page == 0) {
              that.set("data", []);
            }

            that.set("isLoadingData", false);
          }
        });
      },
      setupData: function setupData() {
        // this.set('table', new Table(this.get('columns'), this.get('data')));
        // let table = new Table(this.get('columns'), this.get('data'), { enableSync: this.get('enableSync') });
        var table = _emberLightTable.default.create({
          columns: this.get('columns'),
          rows: this.get('data')
        });

        var sortColumn = table.get('allColumns').findBy('valuePath', this.get('sort'));

        if (sortColumn) {
          sortColumn.set('sorted', true);
        }

        this.set('table', table);
      },
      onScrolledToBottom: function onScrolledToBottom() {
        if (this.get('canLoadMore')) {
          this.send("loadPageData");
        }
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath'),
            canLoadMore: true,
            page: 0
          }); // this.get('model').update();
        }
      }
    },
    columns: Ember.computed(function () {
      return [{
        label: '',
        valuePath: 'post_image',
        width: '70px',
        sortable: false,
        cellComponent: 'post-image'
      }, {
        label: 'Price',
        valuePath: 'post_price',
        cellComponent: 'post-price'
      }, {
        label: 'Platform',
        valuePath: 'post_social_type',
        cellComponent: 'post-platform',
        sortable: false
      }, {
        label: 'Created',
        valuePath: 'created_datetime',
        cellComponent: 'post-date'
      }, {
        label: 'Updated',
        valuePath: 'updated_date',
        cellComponent: 'post-date'
      }, {
        label: 'Status',
        valuePath: 'status',
        cellComponent: 'post-status'
      }];
    }) // table: computed('model', function() {
    //   return new Table(this.get('columns'), this.get('data'));
    // }),

  });

  _exports.default = _default;
});