define("flytrendy/controllers/campaigns-pending", ["exports", "flytrendy/config/environment", "flytrendy/models/objects/campaign"], function (_exports, _environment, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    applicationController: Ember.inject.controller("application"),
    platforms: _environment.default.APP.platforms,
    self_service: _environment.default.APP.self_service,
    brand: null,
    queryParams: ["brand"],
    data: [],
    init: function init() {
      console.log(this.platforms);
    },
    actions: {
      onFilterCampaigns: function onFilterCampaigns(e) {
        var val = $(e.target).find(":checked").val().toLowerCase();
        var platform_prefix = val === "all" ? "" : "&platform_names=".concat(val);
        this.set("page", 1);
        this.set("data", []);
        this.send("loadData", platform_prefix);
      },
      loadData: function loadData(campaign_type) {
        var origin_platform = campaign_type || ""; //   if (origin_platform.includes("flytrendy")) {
        //     origin_platform = origin_platform + "&io_platform=false";
        //   }

        var controller = this;
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var brand_id = controller.get("brand");
        var page = this.get("page") || 1;
        var endpoint = _environment.default.APP.API_HOST + "/staff/campaigns?pending=true&page=" + page + origin_platform;

        if (brand_id) {
          endpoint = endpoint + "&brand_id=" + brand_id;
        }

        controller.set("is_loading", true);
        Ember.$.ajax({
          type: "GET",
          url: endpoint,
          dataType: "json",
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", userauth);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
          },
          success: function success(res) {
            var data = [];

            if (res && res.campaign) {
              var obj = null,
                  campaignList = [],
                  campObj = null;

              for (var i = 0; i < res.campaign.length; i++) {
                obj = res.campaign[i];
                campObj = _campaign.default.create(obj);
                campObj.set("data_id", obj.id);
                data.push(campObj);
              }
            }

            controller.set("data", data);
            controller.set("is_loading", false);
          },
          error: function error(jqXHR, exception) {
            controller.set("data", []);
            console.log(jqXHR);
            controller.set("is_loading", false);
          }
        });
      },
      deleteCampaign: function deleteCampaign(campaign_id) {
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var that = this;
        var r = confirm("Delete this campaign?");

        if (r == true) {
          that.get("applicationController").send("showLoader");
          Ember.$.ajax({
            type: "DELETE",
            url: _environment.default.APP.API_HOST + "/campaigns/" + campaign_id,
            dataType: "json",
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader("Authorization", userauth);
              xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
            },
            success: function success(res) {
              that.get("applicationController").send("hideLoader");
              that.get("applicationController").send("showToast", "Campaign deleted!", "Success", null);
            },
            error: function error(jqXHR, exception) {
              that.get("applicationController").send("hideLoader");
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});