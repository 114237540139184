define("flytrendy/controllers/influencers", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    data: {},
    totalInfl: 0,
    totalMale: 0,
    totalFemale: 0,
    totalReach: 0,
    geoData: [['Country', 'Popularity']],
    geoOptions: {
      height: 400,
      width: '100%'
    },
    barData: [['', 'Facebook', "Twitter", 'Instagram'], ["Social Media", 0, 0, 0]],
    barOptions: {
      height: 400,
      width: '100%',
      backgroundColor: 'transparent',
      chart: {
        title: 'Total Reach',
        subtitle: ''
      }
    },
    lineData: [["Influencers Growth", 'Influencers']],
    lineOptions: {
      height: 400,
      width: '100%',
      backgroundColor: 'transparent'
    },
    pieData: [['Task', 'Post Status'], ['Pending', 11.1], ['Approved', 2], ['Withdrawn', 2], ['Rejected', 2], ['Published', 7]],
    pieOptions: {
      height: 400,
      width: '100%',
      backgroundColor: 'transparent'
    },
    interestData: [['', 'Sports, Fitness & Wellbeing', 'Books, Magazines & Games', 'Online Shopping & Entertainment', 'Food, Lifestyle & Events', 'Music & Movies', 'Education & Technology', 'Photography', 'Travel & Cars', 'Social & Family'], ["Influencers by Interests", 100, 45, 123, 200, 450, 1000, 800, 900, 350]],
    interestOptions: {
      height: 400,
      width: '100%',
      backgroundColor: 'transparent',
      chart: {
        title: 'Interests',
        subtitle: ''
      }
    },
    init: function init() {},
    actions: {
      setLocationGraph: function setLocationGraph(data) {
        google.charts.load('current', {
          'packages': ['geochart']
        });
        google.charts.setOnLoadCallback(function () {
          var chart = new google.visualization.GeoChart(document.getElementById('geo_chart'));
          chart.draw(google.visualization.arrayToDataTable(data), {
            'title': 'Influencers by location',
            'width': '100%',
            'height': 400
          });
        });
        this.send("setInterestGraph", {});
      },
      setReachGraph: function setReachGraph(data) {
        google.charts.load('current', {
          'packages': ['bar']
        });
        google.charts.setOnLoadCallback(function () {
          var chart = new google.charts.Bar(document.getElementById('total_reach_chart'));
          chart.draw(google.visualization.arrayToDataTable(data), {
            'title': 'Influencers total reach',
            'width': '100%',
            'height': 400
          });
        });
      },
      setGrowthGraph: function setGrowthGraph(data) {
        google.charts.load('current', {
          'packages': ['corechart']
        });
        google.charts.setOnLoadCallback(function () {
          var chart = new google.visualization.LineChart(document.getElementById('growth_chart'));
          chart.draw(google.visualization.arrayToDataTable(data), {
            'title': 'Influencers growth by year',
            'width': '100%',
            'height': 400,
            'legend': {
              position: 'bottom'
            },
            tooltip: 'false',
            hAxis: {
              format: '0000'
            },
            curveType: 'function'
          });
        });
      },
      setPostsGraph: function setPostsGraph(data) {
        google.charts.load('current', {
          'packages': ['corechart']
        });
        google.charts.setOnLoadCallback(function () {
          var chart = new google.visualization.PieChart(document.getElementById('posts_chart'));
          chart.draw(google.visualization.arrayToDataTable(data), {
            'title': 'Posts by status',
            'is3D': true,
            'width': '100%',
            'height': 400
          });
        });
      },
      setInterestGraph: function setInterestGraph(data) {
        data = this.get("interestData");
        google.charts.load('current', {
          'packages': ['bar']
        });
        google.charts.setOnLoadCallback(function () {
          var chart = new google.charts.Bar(document.getElementById('interests_chart'));
          chart.draw(google.visualization.arrayToDataTable(data), {
            'title': 'Influencers by interests',
            'width': '100%',
            'height': 400
          });
        });
      }
    }
  });

  _exports.default = _default;
});