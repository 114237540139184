define("flytrendy/models/objects/post", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    id: "",
    campaign: "",
    user: "",
    post_social_type: "",
    social_account_info: "",
    content_rights_info: "",
    brand_name: "",
    post_copy: "",
    post_image_info: "",
    post_price: "",
    post_discount: "",
    video_url: "",
    status: "",
    brand_feedback: "",
    created_datetime: "",
    updated_date: "",
    seen: "",
    reviewed: false,
    post_image: false,
    influencer_email: "",
    jungler_platform: _environment.default.APP.jungler_platform,
    init: function init(obj) {},
    setPostImage: function setPostImage(controller) {
      var url = _environment.default.APP.IMAGES_URL + "/post_images/" + this.get("campaign") + "/" + this.get("campaign") + "-" + this.get("id") + ".png"; // var url = "http://images.flytrendy.com/post_images/184/184-1529.png";

      this.set("post_image", url); // this.set("social_account_info", {"username": "ilaria.vanzan", "name": "ILARIA VANZAN \ud83c\udf80", "accessToken": "434446363.7438b3d.3bc6929d73de408ca99fa8136d9373ff", "instagram_business_id": "", "profile_picture": "https://scontent.cdninstagram.com/vp/720237d71ebef786ac3a6f643bb57d78/5D942CA2/t51.2885-19/61256112_680898872362882_6608153578303389696_n.jpg?_nc_ht=scontent.cdninstagram.com", "followers": 10221, "id": "434446363"});
    }
  });

  _exports.default = _default;
});