define("flytrendy/components/scatter-chart", ["exports", "ember-google-charts/components/scatter-chart"], function (_exports, _scatterChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scatterChart.default;
    }
  });
});