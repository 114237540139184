define("flytrendy/routes/view-campaign", ["exports", "ember-simple-auth/mixins/application-route-mixin", "flytrendy/config/environment", "flytrendy/models/objects/campaign"], function (_exports, _applicationRouteMixin, _environment, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service("session"),
    setupController: function setupController(controller, model) {
      var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
      var campaign_id = controller.get("campaign");
      var endpoint = _environment.default.APP.API_HOST + "/staff/campaigns";

      if (campaign_id) {
        endpoint = _environment.default.APP.API_HOST + "/staff/campaigns?campaign_id=" + campaign_id;
      }

      controller.set("loading_campaign", true);
      Ember.$.ajax({
        type: "GET",
        url: endpoint,
        dataType: "json",
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader("Authorization", userauth);
          xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        },
        success: function success(res) {
          var data = {};

          if (res && res.campaign) {
            var obj = null,
                campObj = null;

            if (res.campaign.length > 0) {
              obj = res.campaign[0];
              campObj = _campaign.default.create(obj);
              campObj.set("data_id", obj.id); // campObj.setHeroImage(controller);
              // campObj.setLogoImage(controller);

              controller.set("data", data);
              campObj.setMoodImages(controller, function (moods) {
                controller.send("setupMoodBoard", moods);
              });
              data = campObj;
            }
          }

          controller.set("data", data);
          controller.set("loading_campaign", false);
          setTimeout(function () {
            controller.send("loadData");
          }, 500);
        },
        error: function error(jqXHR, exception) {
          controller.set("data", []);
          controller.set("loading_campaign", false);
          console.log(jqXHR);
        }
      });
      setTimeout(function () {
        controller.send("onLoading");
      }, 1000);
    }
  });

  _exports.default = _default;
});