define("flytrendy/controllers/post-payments", ["exports", "flytrendy/config/environment", "ember-light-table", "ember-concurrency", "flytrendy/models/objects/post", "flytrendy/models/objects/campaign"], function (_exports, _environment, _emberLightTable, _emberConcurrency, _post, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend(_defineProperty({
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    campaign: null,
    queryParams: ['campaign'],
    data: [],
    databyids: {},
    campaigns: [],
    store: Ember.inject.service(),
    page: 0,
    canLoadMore: true,
    enableSync: true,
    isLoadingData: true,
    galleryOptions: {
      hideShare: true,
      hideZoomInOut: false,
      hideToggleFullScreen: false
    },
    table: null,
    postGalleryData: [{}],
    init: function init() {
      this._super.apply(this, arguments);

      var table = _emberLightTable.default.create({
        columns: this.get('columns'),
        rows: this.get('data')
      });

      this.set('table', table);
    },
    actions: {
      onLoading: function onLoading() {
        this.send("loadPageData");
      },
      onFilterPosts: function onFilterPosts(e) {
        var val = $(e.target).find(":checked").val().toLowerCase();
        this.set("page", 0);
        this.send("setupData");
        this.set("canLoadMore", true);
        this.set("data", []);
      },
      onPostApprovePayout: function onPostApprovePayout(row) {
        var post_id = row.get("id");
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var payout_endpoint = _environment.default.APP.API_HOST + "/campaignpostmakepayment?campaign_post_id=" + post_id;
        var endpoint = _environment.default.APP.API_HOST + "/staff/posts";
        var that = this;
        this.get("applicationController").send("showLoader");
        Ember.$.ajax({
          type: "POST",
          url: payout_endpoint,
          // data: JSON.stringify(data),
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", userauth);
            xhr.setRequestHeader("Content-Type", "application/json");
          },
          success: function success(res) {
            updatePostAfterPayOut(row);
            that.get("applicationController").send("hideLoader");

            if (res && res.campaignPostMakePayment && res.campaignPostMakePayment.success) {
              var d = that.get('data');
              $.each(d, function (i) {
                if (d[i].id === row.get("id")) {
                  d.splice(i, 1);
                  return false;
                }
              });
              that.get('table').removeRow(row);
            }

            that.send("loadPageData");
          },
          error: function error(jqXHR, exception) {
            that.get("applicationController").send("hideLoader");
          }
        });

        function updatePostAfterPayOut(row) {
          var that = this;
          var data = {
            "getPostsStaff": {
              "post_id": post_id,
              "staff_status": 'paid',
              "payment_failed": false
            }
          };
          Ember.$.ajax({
            type: "POST",
            url: endpoint,
            data: JSON.stringify(data),
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader("Authorization", userauth);
              xhr.setRequestHeader("Content-Type", "application/json");
            },
            success: function success(res) {//var that = this;
              //that.get("applicationController").send("hideLoader");
            },
            error: function error(jqXHR, exception) {
              that.get("applicationController").send("hideLoader");
            }
          });
        }
      },
      viewPost: function viewPost(row) {
        // Option buttons to be added -- #BUG
        // https://photoswipe.com/documentation/custom-html-in-slides.html
        var that = this,
            postObj = null;
        var is_video = row.get("video_url");

        if (is_video) {
          var video = document.createElement("video");
          video.src = row.get('video_url');
          $.colorbox({
            html: "<video width='720' controls autoplay><source src=" + video.src + " type='video/mp4'></video>"
          });
        } else {
          var img = document.createElement("img");
          img.src = row.get('post_image');

          img.onload = function () {
            postObj = new Object();
            postObj.src = row.get("post_image");
            postObj.w = img.width;
            postObj.h = img.height;
            postObj.title = row.get("post_copy");
            that.set("postGalleryData", [postObj]);
            var pswpElement = document.querySelectorAll('.pswp')[0];
            var gallery = new PhotoSwipe(pswpElement, false, that.get("postGalleryData"), {
              bgOpacity: 0.8,
              history: false,
              focus: false,
              showAnimationDuration: 0,
              hideAnimationDuration: 0
            });
            gallery.init();
          };
        }
      },
      loadPageData: function loadPageData() {
        this.set("isLoadingData", true);
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var campaign_id = this.get("campaign");
        var that = this,
            page = this.get("page");
        var endpoint = _environment.default.APP.API_HOST + "/staff/posts?payment_failed=True";
        /*&page="+page;*/

        Ember.$.ajax({
          type: "GET",
          url: endpoint,
          dataType: "json",
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", userauth);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
          },
          success: function success(res) {
            var data = that.get("data"),
                canLoadMore = false,
                databyids = that.get("databyids");

            if (res && res.getPostsStaff) {
              var obj = null,
                  postObj = null;

              for (var i = 0; i < res.getPostsStaff.length; i++) {
                obj = res.getPostsStaff[i];

                if (!databyids[obj.id]) {
                  postObj = _post.default.create(obj);
                  postObj.setPostImage(that);
                  databyids[obj.id] = postObj;
                  data.push(postObj);
                }
              }

              if (res.getPostsStaff.length > 0) {
                page = page + 1;
                canLoadMore = true;
              }
            }

            that.set("databyids", databyids);
            that.set("data", data);
            that.set("canLoadMore", canLoadMore);
            that.set("page", page);
            that.send("setupData");
            that.set("isLoadingData", false);
          },
          error: function error(jqXHR, exception) {
            if (page == 0) {
              that.set("data", []);
            }

            that.set("isLoadingData", false);
          }
        });
      },
      setupData: function setupData() {
        // let table = new Table(this.get('columns'), this.get('data'), { enableSync: this.get('enableSync') });
        var table = _emberLightTable.default.create({
          columns: this.get('columns'),
          rows: this.get('data')
        });

        var sortColumn = table.get('allColumns').findBy('valuePath', this.get('sort'));

        if (sortColumn) {
          sortColumn.set('sorted', true);
        }

        this.set('table', table);
      },
      onScrolledToBottom: function onScrolledToBottom() {
        if (this.get('canLoadMore')) {
          this.send("loadPageData");
        }
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath'),
            canLoadMore: true,
            page: 0
          });
        }
      }
    },
    columns: Ember.computed(function () {
      return [{
        label: '',
        valuePath: 'post_image',
        width: '70px',
        sortable: false,
        cellComponent: 'post-image'
      }, {
        label: 'Price',
        valuePath: 'post_price',
        cellComponent: 'post-price'
      }, {
        label: 'Platform',
        valuePath: 'post_social_type',
        cellComponent: 'post-platform',
        sortable: false
      }, {
        label: 'Created',
        valuePath: 'created_datetime',
        cellComponent: 'post-date'
      }, {
        label: 'Updated',
        valuePath: 'updated_date',
        cellComponent: 'post-date'
      }];
    })
  }, "table", Ember.computed('model', function () {
    return new _emberLightTable.default(this.get('columns'), this.get('data'));
  })));

  _exports.default = _default;
});