define("flytrendy/templates/components/materialize-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NRMRA5z7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\"],[11,\"class\",\"lt-sort-icon material-icons\"],[11,\"style\",\"height:0px;\"],[9],[1,[27,\"get\",[[23,[\"sortIcons\"]],[23,[\"sortIconProperty\"]]],null],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "flytrendy/templates/components/materialize-icon.hbs"
    }
  });

  _exports.default = _default;
});