define("flytrendy/controllers/login", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    applicationController: Ember.inject.controller("application"),
    init: function init() {
      if (this.get("session.isAuthenticated")) {
        this.transitionToRoute("campaigns");
      }
    },
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var _this$getProperties = this.getProperties("identification", "password"),
            identification = _this$getProperties.identification,
            password = _this$getProperties.password;

        var that = this;

        if (identification && password) {
          that.get("applicationController").send("showLoader");
          this.get("session").authenticate("authenticator:oauth2", identification, password).then(function (result) {
            var user_token = _this.get("session.data.authenticated.access_token");

            _this.get("session").set("data.username", identification);

            _this.get("session").set("data.password", password);

            _this.transitionToRoute("campaigns");

            that.get("applicationController").send("hideLoader");
          }, function (err) {
            var user_token = _this.get("session.data.authenticated.access_token");

            if (user_token) {
              _this.get("session").set("data.username", identification);

              _this.get("session").set("data.password", password);

              _this.transitionToRoute("campaigns");

              that.get("applicationController").send("hideLoader");
            } else {
              that.get("applicationController").send("hideLoader");
              alert("Enter a valid username/password.");
            }
          });
        } else {
          that.get("applicationController").send("hideLoader");
          alert("username/password not provided");
        }
      }
    }
  });

  _exports.default = _default;
});