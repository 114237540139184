define("flytrendy/templates/components/post-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8OI2Hzoc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"post-content\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"col-md-6\"],[9],[0,\"\\n\\n\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"user-cont\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"img\"],[12,\"src\",[23,[\"post\",\"social_account_info\",\"profile_picture\"]]],[9],[10],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\"],[11,\"class\",\"influencer-name\"],[9],[1,[23,[\"post\",\"social_account_info\",\"name\"]],false],[10],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\"],[9],[1,[23,[\"post\",\"social_account_info\",\"followers\"]],false],[0,\" Followers\"],[10],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\"],[9],[7,\"i\"],[11,\"class\",\"fas fa-paper-plane fa-sm\"],[9],[10],[0,\" \"],[7,\"a\"],[12,\"href\",[28,[\"mailto: \",[23,[\"post\",\"influencer_email\"]]]]],[9],[1,[23,[\"post\",\"influencer_email\"]],false],[10],[10],[0,\"\\n\\t\\t\\t\"],[10],[0,\"\\n\\n\\t\\t\"],[10],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"col-md-6\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"p\"],[11,\"class\",\"post-copy\"],[9],[1,[23,[\"post\",\"post_copy\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"post\",\"brand_feedback\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"p\"],[11,\"class\",\"post-feedback\"],[9],[7,\"span\"],[9],[0,\"Feedback:\"],[10],[0,\" \"],[1,[23,[\"post\",\"brand_feedback\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "flytrendy/templates/components/post-content.hbs"
    }
  });

  _exports.default = _default;
});