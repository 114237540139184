define("flytrendy/controllers/campaigns", ["exports", "flytrendy/config/environment", "flytrendy/models/objects/campaign"], function (_exports, _environment, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    applicationController: Ember.inject.controller("application"),
    brand: null,
    platforms: _environment.default.APP.platforms,
    self_service: _environment.default.APP.self_service,
    queryParams: ["brand"],
    data: [],
    is_loading: true,
    isLoadingData: false,
    canLoadMore: true,
    page: 1,
    init: function init() {
      var that = this;
      $(window).scroll(function () {
        if ($(window).scrollTop() + $(window).height() === $(document).height()) {
          that.onScrolled();
        }
      });
    },
    onScrolled: function onScrolled() {
      if (this.get("canLoadMore")) {
        this.send("loadData");
      }
    },
    actions: {
      onFilterCampaigns: function onFilterCampaigns(e) {
        var platform = $(e.target).find(":checked").val().toLowerCase();
        this.set("page", 1);
        this.set("data", []);

        if (platform === "all") {
          this.send("loadData");
          return;
        }

        this.send("loadData", "&platform_names=" + platform);
      },
      loadData: function loadData(campaign_type) {
        var origin_platform = campaign_type || "";
        var controller = this;
        controller.set("isLoadingData", true);
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var brand_id = controller.get("brand");
        var page = this.get("page");
        var endpoint = _environment.default.APP.API_HOST + "/staff/campaigns?pending=false&page=" + page + origin_platform;

        if (brand_id) {
          endpoint = endpoint + "&brand_id=" + brand_id;
        }

        if (page === 1) {
          controller.set("is_loading", true);
        } else {
          controller.set("is_loading", false);
        }

        Ember.$.ajax({
          type: "GET",
          url: endpoint,
          dataType: "json",
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", userauth);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
          },
          success: function success(res) {
            var data = controller.get("data"),
                canLoadMore = false;
            var newPageData = [];

            if (res && res.campaign) {
              var obj = null,
                  campaignList = [],
                  campObj = null;

              for (var i = 0; i < res.campaign.length; i++) {
                obj = res.campaign[i];
                campObj = _campaign.default.create(obj);
                campObj.set("data_id", obj.id);
                newPageData.push(campObj);
              }
            }

            if (res.meta && res.meta.next) {
              page = page + 1;
              canLoadMore = true;
            } else {
              canLoadMore = false;
            }

            controller.set("data", data.concat(newPageData));
            controller.set("page", page);
            controller.set("is_loading", false);
            controller.set("canLoadMore", canLoadMore);
            controller.set("isLoadingData", false);
          },
          error: function error(jqXHR, exception) {
            controller.set("data", []);
            controller.set("is_loading", false);
            controller.set("isLoadingData", false);
          }
        });
      },
      emailReport: function emailReport(campaign_id) {
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var that = this;
        that.get("applicationController").send("showLoader");
        Ember.$.ajax({
          type: "GET",
          url: _environment.default.APP.API_HOST + "/campaignsummary?security_code=lkswmv67wezcllmrrgw6&campaign_id=" + campaign_id,
          dataType: "json",
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader("Authorization", userauth);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
          },
          success: function success(res) {
            that.get("applicationController").send("hideLoader");
            that.get("applicationController").send("showToast", "Campaign report sent!", "Success", null);
          },
          error: function error(jqXHR, exception) {
            that.get("applicationController").send("hideLoader");
          }
        });
      },
      deleteCampaign: function deleteCampaign(campaign_id) {
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var that = this;
        var r = confirm("Delete this campaign?");

        if (r == true) {
          that.get("applicationController").send("showLoader");
          Ember.$.ajax({
            type: "DELETE",
            url: _environment.default.APP.API_HOST + "/campaigns/" + campaign_id,
            dataType: "json",
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader("Authorization", userauth);
              xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
            },
            success: function success(res) {
              that.get("applicationController").send("hideLoader");
              that.get("applicationController").send("showToast", "Campaign deleted!", "Success", null);
            },
            error: function error(jqXHR, exception) {
              that.get("applicationController").send("hideLoader");
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});