define("flytrendy/controllers/push", ["exports", "flytrendy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    region: [],
    all: false,
    watchAll: function () {
      this.set('region', 'all');
      $('.region-label').toggle();
      $('#valledaosta').toggle();
      $('#piemonte').toggle();
      $('#liguria').toggle();
      $('#lombardia').toggle();
      $('#trentinoaltoadige').toggle();
      $('#veneto').toggle();
      $('#friuliveneziagiulia').toggle();
      $('#emiliaromagna').toggle();
      $('#toscana').toggle();
      $('#umbria').toggle();
      $('#lazio').toggle();
      $('#marche').toggle();
      $('#abruzzo').toggle();
      $('#molise').toggle();
      $('#campania').toggle();
      $('#puglia').toggle();
      $('#basilicata').toggle();
      $('#calabria').toggle();
      $('#sardegna').toggle();
      $('#sicilia').toggle();
    }.observes('all'),
    applicationController: Ember.inject.controller('application'),
    init: function init() {
      if (this.get("session.isAuthenticated")) {// this.transitionToRoute('campaigns');
      }
    },
    actions: {
      sendpush: function sendpush() {
        var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
        var that = this;
        that.get("applicationController").send("showLoader");
        var push_message = this.get('push_message');
        var ios = this.get('ios');

        if (ios) {
          ios = true;
        } else {
          ios = false;
        }

        var android = this.get('android');

        if (android) {
          android = true;
        } else {
          android = false;
        }

        var male = this.get('male');

        if (male) {
          male = true;
        } else {
          male = false;
        }

        var female = this.get('female');

        if (female) {
          female = true;
        } else {
          female = false;
        }

        var region = [];
        var all = this.get('all');

        if (all) {
          region.length = 0;
          region.push("all");
        }

        var valledaosta = this.get('valledaosta');

        if (valledaosta) {
          region.push("Valle d'Aosta");
        }

        var piemonte = this.get('piemonte');

        if (piemonte) {
          region.push("Piemonte");
        }

        var liguria = this.get('liguria');

        if (liguria) {
          region.push("Liguria");
        }

        var lombardia = this.get('lombardia');

        if (lombardia) {
          region.push("Lombardia");
        }

        var trentinoaltoadige = this.get('trentinoaltoadige');

        if (trentinoaltoadige) {
          region.push("Trentino-Alto Adige");
        }

        var veneto = this.get('veneto');

        if (veneto) {
          region.push("Veneto");
        }

        var friuliveneziagiulia = this.get('friuliveneziagiulia');

        if (friuliveneziagiulia) {
          region.push("Friuli-Venezia Giulia");
        }

        var emiliaromagna = this.get('emiliaromagna');

        if (emiliaromagna) {
          region.push("Emilia-Romagna");
        }

        var toscana = this.get('toscana');

        if (toscana) {
          region.push("Toscana");
        }

        var umbria = this.get('umbria');

        if (umbria) {
          region.push("Umbria");
        }

        var lazio = this.get('lazio');

        if (lazio) {
          region.push("Lazio");
        }

        var marche = this.get('marche');

        if (marche) {
          region.push("Marche");
        }

        var abruzzo = this.get('abruzzo');

        if (abruzzo) {
          region.push("Abruzzo");
        }

        var molise = this.get('molise');

        if (molise) {
          region.push("Molise");
        }

        var campania = this.get('campania');

        if (campania) {
          region.push("Campania");
        }

        var puglia = this.get('puglia');

        if (puglia) {
          region.push("Puglia");
        }

        var basilicata = this.get('basilicata');

        if (basilicata) {
          region.push("Basilicata");
        }

        var calabria = this.get('calabria');

        if (calabria) {
          region.push("Calabria");
        }

        var sardegna = this.get('sardegna');

        if (sardegna) {
          region.push("Sardegna");
        }

        var sicilia = this.get('sicilia');

        if (sicilia) {
          region.push("Sicilia");
        }

        var interests = "";
        var age_min = this.get('age_min');

        if (age_min) {
          age_min = age_min;
        } else {
          age_min = 18;
        }

        var age_max = this.get('age_max');

        if (age_max) {
          age_max = age_max;
        } else {
          age_max = 65;
        }

        var data = {
          "staffPushView": {
            "push_message": push_message,
            "ios": ios,
            "android": android,
            "male": male,
            "female": female,
            "region": region,
            "interests": interests,
            "age_min": age_min,
            "age_max": age_max
          }
        };

        if (confirm('Are you sure you want to send push?')) {
          Ember.$.ajax({
            type: "POST",
            url: _environment.default.APP.API_HOST + "/staff/push/5403e87d-98a2-44e6-a4c7-357655e51fcc",
            dataType: "json",
            data: JSON.stringify(data),
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader("Authorization", userauth);
              xhr.setRequestHeader("Content-Type", "application/json");
            },
            success: function success(res) {
              that.get("applicationController").send("hideLoader");
              that.get("applicationController").send("showToast", "Push notification sent!", "Success", null);
              document.getElementById('pushForm').reset();
            },
            error: function error(jqXHR, exception) {
              that.get("applicationController").send("hideLoader");
            }
          });
        } else {
          that.get("applicationController").send("hideLoader");
        }
      }
    }
  });

  _exports.default = _default;
});