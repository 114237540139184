define("flytrendy/templates/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FtVryS+5",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"main-wrapper\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\\t\\t\"],[7,\"h2\"],[11,\"class\",\"title\"],[9],[0,\"Analytics\"],[10],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"data-content\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"p\"],[9],[0,\"In Progress.\"],[10],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "flytrendy/templates/analytics.hbs"
    }
  });

  _exports.default = _default;
});