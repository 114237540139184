define("flytrendy/routes/brands", ["exports", "ember-simple-auth/mixins/application-route-mixin", "flytrendy/config/environment", "flytrendy/models/objects/brand"], function (_exports, _applicationRouteMixin, _environment, _brand) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service('session'),
    setupController: function setupController(controller, model) {
      var userauth = this.get("session.data.authenticated.token_type") + " " + this.get("session.data.authenticated.access_token");
      Ember.$.ajax({
        type: "GET",
        url: _environment.default.APP.API_HOST + "/staff/brands",
        dataType: "json",
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader("Authorization", userauth);
          xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        },
        success: function success(res) {
          var data = [];

          if (res && res.getBrandsStaff) {
            var obj = null,
                brandObj = null;

            for (var i = 0; i < res.getBrandsStaff.length; i++) {
              obj = res.getBrandsStaff[i];
              brandObj = _brand.default.create(obj);
              brandObj.setLogo(controller);
              data.push(brandObj);
            }
          }

          controller.set("data", data); // var data = [];
          // if(res && res.getBrandsStaff){
          // 	data = res.getBrandsStaff;
          // }
          // controller.set("data", data);
        },
        error: function error(jqXHR, exception) {
          controller.set("data", []);
          console.log(jqXHR);
        }
      });
    }
  });

  _exports.default = _default;
});