define("flytrendy/routes/posts", ["exports", "ember-simple-auth/mixins/application-route-mixin", "flytrendy/config/environment"], function (_exports, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service('session'),
    model: function model() {},
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.send("onLoading");
    }
  });

  _exports.default = _default;
});