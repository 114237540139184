define("flytrendy/authenticators/oauth2", ["exports", "flytrendy/config/environment", "ember-simple-auth/authenticators/oauth2-password-grant"], function (_exports, _environment, _oauth2PasswordGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.APP.API_HOST + '/o/token/',
    makeRequest: function makeRequest(url, data) {
      data.client_id = _environment.default.APP.client_id;
      data.client_secret = _environment.default.APP.client_secret;
      return this._super(url, data);
    }
  });

  _exports.default = _default;
});